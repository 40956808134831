import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { connect, useSelector } from 'react-redux';
import { Theme, ErrorBoundary, SizerWrapper } from '@commonsku/styles';
import { createGlobalStyle } from 'styled-components';

import { getPopups, getPurchaseOrders } from '../selectors';

import Header from './Header';
import MainSection from './MainSection';
import Overlay from '../components/Overlay';
import ProductionReportView from '../components/ProductionReportView';
import POSidePanel from '../components/production-report/POSidePanel';

import { createNewFeaturePopup } from '../actions/popup';

import createPopup from '../popup-factory';

import { reducers } from '../store/configureProductionReportStore';
import withReducers from '../store/withReducers';

import { oauth } from '../utils';
import ReactTooltip from 'react-tooltip';
import {ToastContainer} from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import '../../scss/css/production_report.css';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "skufont-bold";
    src: url("fonts/TT-Norms-Pro-Bold.eot");
    src: url("fonts/TT-Norms-Pro-Bold.otf") format("opentype"),
        url("fonts/TT-Norms-Pro-Bold.ttf") format("TrueType"),
        url("fonts/TT-Norms-Pro-Bold.woff") format("woff"),
        url("fonts/TT-Norms-Pro-Bold.woff2") format("woff2");
  }
  @font-face {
    font-family: "skufont-demibold";
    src: url("fonts/TT-Norms-Pro-DemiBold.eot");
    src: url("fonts/TT-Norms-Pro-DemiBold.otf") format("opentype"),
        url("fonts/TT-Norms-Pro-DemiBold.ttf") format("TrueType"),
        url("fonts/TT-Norms-Pro-DemiBold.woff") format("woff"),
        url("fonts/TT-Norms-Pro-DemiBold.woff2") format("woff2");
  }
  @font-face {
    font-family: "skufont-medium";
    src: url("fonts/TT-Norms-Pro-Medium.eot");
    src: url("fonts/TT-Norms-Pro-Medium.otf") format("opentype"),
        url("fonts/TT-Norms-Pro-Medium.ttf") format("TrueType"),
        url("fonts/TT-Norms-Pro-Medium.woff") format("woff"),
        url("fonts/TT-Norms-Pro-Medium.woff2") format("woff2");
  }
  @font-face {
    font-family: "skufont-regular";
    src: url("fonts/TT-Norms-Pro-Regular.eot");
    src: url("fonts/TT-Norms-Pro-Regular.otf") format("opentype"),
        url("fonts/TT-Norms-Pro-Regular.ttf") format("TrueType"),
        url("fonts/TT-Norms-Pro-Regular.woff") format("woff"),
        url("fonts/TT-Norms-Pro-Regular.woff2") format("woff2");
  }

  .csku-styles {
    font-family: ${props => props.theme.fontFamily};
  }

  .mention-textarea-container {
    position: relative;

    textarea {
        padding: .5rem;
        color: #123952;
        width: 100%;
        height: 150px !important;    
        border: 1px solid #ABC7D1;
        border-radius: 5px;
        box-sizing: border-box;
        font-family: 'skufont-regular',sans-serif;
        font-size: 1rem;
        background-color: white;
        box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
        margin-bottom: 1rem;
    }
  
    ul.tag-results {
      list-style: none;
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      width: 325px;
      padding: 0;
      margin: 0;
      background: #fff;
      position: absolute;
      z-index: 99;
      box-shadow: 1px 1px 1px #ddd;
      display: block;
    }
  
    .tag-user {
      position: relative;
      padding: .2rem 4rem;
  
      &:last-child {
        padding-bottom: .4rem;
      }
  
      &:hover {
        background: $light-gray;
      }
  
      img {
        position: absolute;
        top: .4rem;
        left: .4rem;
      }
  
      .username {
        font-size: .7em;
        cursor: pointer;
      }
    }
  }

  .tasks-comment {
    .mention-textarea-container {
      textarea {
        height :50px !important 
      }
    }
  }

`;

function ProductionReportApp({ identity, onCreateNewFeaturePopup, popups, ...props }) {
    const [selectedSidePanelTab, setSelectedSidePanelTab] = useState('notes');
    const [selectedPOId, setSelectedPOId] = useState(false);
    const pos = useSelector(getPurchaseOrders);
    const selectedPO = get(pos, [selectedPOId], false);

    useEffect(function () {
        // remove resku from body and html tags
        document.getElementsByTagName('body')[0].className = '';
        document.getElementsByTagName('html')[0].className = '';
        document.body.style = "background: #eef1f4;";
        oauth('GET', `user/${identity.user_id}`, { should_show_popup: true }).then(({ json }) => {
          if (json.show_popup == 1) {
            onCreateNewFeaturePopup();
          }
        });
        injectStyle();
      }, []);

    //locking the page again when side panel is shown
    /* useEffect(() => {
      if(sidePanelRow) {
        setTimeout(() => {
          document.body.style.overflow = 'hidden'
        }, 1000)
      }else{
        setTimeout(() => {
          document.body.style.overflow = 'auto'
        }, 1000)
      }
    }, [sidePanelRow]) */

    return (
        <Theme>
            <GlobalStyle theme={{ fontFamily: "'skufont-regular', 'sans-serif', 'Roboto'" }} />
            <ErrorBoundary>
                <ToastContainer
                    autoClose={3000}
                    hideProgressBar={true}
                />
            <div className="resku">
                <Header></Header>
            </div>
            <div>
                <ReactTooltip id="commentTip" place="top">View Comments</ReactTooltip>
                <ReactTooltip id ="pinTip" place="top" />
                <ReactTooltip id="deleteTip" place="top">Delete</ReactTooltip>
                <ReactTooltip id="editTaskTip" place="top">Edit</ReactTooltip>
                <div className="csku-styles">
                    <POSidePanel
                        sidePanelRow={selectedPO}
                        setSidePanelRow={setSelectedPOId}
                        selectedTab={selectedSidePanelTab}
                        setSelectedTab={setSelectedSidePanelTab}
                    />
                </div>

                <div className="resku"><MainSection /></div>

                <div className="main-content csku-styles" style={{ marginTop: '0rem', height: '90%' }}>
                    <SizerWrapper
                        xsStyle="padding-left: 0; margin-left: 0;"
                        smStyle="padding-right: 0 !important; padding-left: 10px !important;"
                        mdStyle="padding-left: 10px;"
                        style={{ paddingRight: 0, background: '#ffffff' }}>
                        <ProductionReportView
                            sidePanelRow={selectedPO}
                            setSidePanelRow={(po) => {
                              if (!po || !get(po, ['purchase_order_id'], false)) {
                                setSelectedPOId(false);
                                return;
                              }
                              setSelectedPOId(po.purchase_order_id);
                            }}
                            setSelectedSidePanelTab={setSelectedSidePanelTab}
                        />
                    </SizerWrapper>
                </div>
                <div className="resku">
                {popups ? popups.map((p, idx) => createPopup(p, idx, props)) : null}
                </div>
            </div>
            <div className="resku">
                <Overlay popups={popups} />
            </div>
            </ErrorBoundary>
        </Theme>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
      identity: state.identity,
      popups: getPopups(state),
      loading: state.display.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCreateNewFeaturePopup: () => {
            dispatch(createNewFeaturePopup());
        }
    };
};

export default withReducers(connect(mapStateToProps, mapDispatchToProps)(ProductionReportApp), reducers, true);
